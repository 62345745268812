import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./reducers/OcrReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { combineReducers } from "redux";
const reducers = combineReducers({
  ocr: counterReducer,
  // other reducers goes here...
});

const persistConfig = {
  key: "root",
  storage: storage,
  //  stateReconciler: hardSet,
};

const _persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// export default configureStore({
//   reducer: {
//     ocr: counterReducer,
//   },
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: false,
//     }),
// });
