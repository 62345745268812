import axios from "axios";

export const setupAxios = (accessToken) => {
    console.log(accessToken, 'token')
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
};


export const isLoggedIn = (check) => {
    let loggedIn = false
    return loggedIn;
}