import axios from "axios";

axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem('token');

export const getApiTemplates = async ({ url }) => {
  try {
    let { data, status } = await axios.get(url);
    return data;
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const getTemplatesList = async ({ url }) => {
  try {
    let { data, status } = await axios.get(url);
    return data;
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const getProcessedData = async ({ url }) => {
  try {
    let { data, status } = await axios.get(url);
    return data;
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const getProcessedDataById = async ({ url }) => {
  try {
    let { data, status } = await axios.get(url);
    return data;
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const DeleteDataById = async ({ url }) => {
  try {
    let { data, status } = await axios.post(url);
    return { data, status };
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const download = async ({ url }) => {
  try {
    let { data, status } = await axios.get(url, {
      responseType: "blob",
    });
    return { data, status };
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const Login = async ({ url, input }) => {
  try {
    let { data, status } = await axios.post(url, input);
    return { data, status };
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const saveNewRows = async ({ url, input }) => {
  try {
    let { data, status } = await axios.post(url, input);
    return { data, status };
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const getCellWiseData = async ({ url, input }) => {
  try {
    let { data, status } = await axios.post(url, input);
    return { data, status };
  } catch (err) {
    return {
      error: true,
    };
  }
};

export const OcrFileUpload = async ({ url, uploadData, config, options }) => {
  try {
    let { data, status } = await axios.post(url, uploadData, options, config);
    return { data, status };
  } catch (error) {
    return {
      error: true,
    };
  }
};

export const OcrFileTranslateText = async ({ url, cropped }) => {
  let { data, status } = await axios({
    method: "POST",
    url: url,
    data: cropped,
  });
  return { data, status };
};
