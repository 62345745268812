import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid, Paper, TextField, Button, Modal, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Login as LoginApi } from "../actions/OcrAction";
import AlertBar from "./CommonUtils/AlertBar";
import { setLoggedInUser } from "../reducers/OcrReducer";
import matrix from "../assets/login.png";
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import crypto from 'crypto';
import OtpInput from 'react-otp-input';
import { width } from "@mui/system";
import {
  Form
} from "semantic-ui-react";
import { isExpired, decodeToken } from "react-jwt";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const Login = (props) => {
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [showPass, setShowpass] = useState(false);
  const [modalopen, setModalOpen] = useState(false);
  const [forgotModalopen, setForgotModalOpen] = useState(false);
  const [code, setCode] = useState('');
  const [token, setAccessToken] = useState('');
  const [emailId, setEmailId] = useState('');
  const [forgotEmail, setForgotEmailId] = useState('');

  useEffect(() => {
    return () => {
      setUserName("");
      setPassword("");
    };
  }, []);

  const handleUserName = (e) => {
    setUserName(e.target.value);
  };
  const handlePassName = (e) => {
    setPassword(e.target.value);
  };
  const navigate = useNavigate();

  // const encode = (data) => {
  //   const cipher = crypto.createCipheriv("aes-256-cbc", "6fa979f20126cb08aa645a8f495f6d85", "7777777a72ddc2f1");

  //   let encryptedData = cipher.update(data, "utf-8", "binary");

  //   encryptedData += cipher.final("binary");

  //   return encryptedData
  // }
  const handleLogin = async () => {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    // props.setLogin();
    let loginData = {
      userName: username,
      password: password,
    };
    let LoggedIn = await LoginApi({
      url: BASE_URL + "login",
      input: loginData,
    });
    if (LoggedIn.error !== undefined && LoggedIn.error === true) {
      setOpenSnack(true);
      setSnackMessage("Failed to Login");
      setAlertColor("error");
      return;
    } else if (LoggedIn.status === 200) {
      setOpenSnack(true);
      setSnackMessage("Logged In Successfully");
      setAlertColor("success");
      localStorage.setItem('token', LoggedIn.data.access_token)
      localStorage.setItem('role', LoggedIn.data.role)
      dispatch(setLoggedInUser(LoggedIn.data));
      setModalOpen(true);
      setAccessToken(LoggedIn.data.access_token)
      setEmailId(LoggedIn.data.emailId)
      let data = {
        emailId: LoggedIn.data.emailId
      }
      const headers = {
        'Authorization': 'Bearer ' + LoggedIn.data.access_token
      }
      axios.post(`${BASE_URL}` + `sendVerificationCode`, data, { headers: headers })
        .then(res => {
          setSnackMessage('Verification Code Send to your email Id')
        })
      setCode('');
      // navigate("/home");
    }
  };
  const handleClose = (event, reason) => {
    console.log('check')
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
    setForgotModalOpen(false);
  };

  const verifyCode = () => {
    let data = {
      emailId: emailId,
      verification_code: code
    }
    const headers = {
      'Authorization': 'Bearer ' + token
    }
    axios.post(`${BASE_URL}` + `checkVerificationCode`, data, { headers: headers })
      .then(response => {
        const decodedToken = decodeToken(response.data.encrypted_word);
        if (decodedToken.word === code) {
          setSnackMessage(response.data.message)
          setModalOpen(false)
          navigate("/home");
        }
        else {
          alert(response.data.message)
        }


      })
      .catch(error => {

      })
  }

  const sendEmail = () => {
    let data = {
      emailId: forgotEmail
    }
    const headers = {
      'Authorization': 'Bearer ' + token
    }
    axios.post(`${BASE_URL}` + `forgotPasswordLink`, data)
      .then(response => {
        if (response.data.message === "User Verification Success") {
          setSnackMessage(response.data.message)
          setModalOpen(false)
          navigate("/home");
        }
        else {
          alert(response.data.message)
        }


      })
      .catch(error => {

      })
  }

  const handleOTP = (data) => {
    setCode(data)
  }

  const forgotPass = () => {
    setForgotModalOpen(true)
  }

  const clodeModal = () => {
    setForgotModalOpen(false)
  }

  const emailOnChange = (e) => {
    setForgotEmailId(e.target.value)
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#1976d2",
      }}
    >
      <AlertBar
        openSnack={openSnack}
        handleClose={handleClose}
        alertColor={alertColor}
        snackMessage={snackMessage}
      />
      <Modal
        open={modalopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          height: 200,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" color={'blue'}>
            Enter Your OTP:
          </Typography>
          <OtpInput
            value={code}
            style={{}}
            onChange={handleOTP}
            numInputs={4}
            separator={<span><span><span>-</span></span></span>}
            containerStyle={{ marginRight: 40, width: 50 }}
            inputStyle={{ width: 80, height: 50 }}
          />
          <Button
            variant="contained"
            size="small"
            onClick={() => setModalOpen(false)}
            style={{ display: 'flex', marginTop: 40, float: 'right', marginLeft: 20 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={verifyCode}
            style={{ display: 'flex', marginTop: 40, float: 'right' }}
          >
            Verify
          </Button>
        </Box>
      </Modal>
      <Modal
        open={forgotModalopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 500,
          height: 200,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}>

          <TextField
            id="outlined-basic"
            label="EmailId"
            variant="outlined"
            style={{ width: '100%' }}
            onChange={emailOnChange}
          />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              size="small"
              onClick={() => setForgotModalOpen(false)}
              style={{ display: 'flex', marginTop: 40, marginRight: 20 }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={sendEmail}
              style={{ display: 'flex', marginTop: 40 }}
            >
              Send Mail
            </Button>
          </div>

        </Box>
      </Modal>
      <Paper
        elevation={10}
        sx={{
          height: 300,
          width: 500,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent={"center"}
          alignItems={"center"}
          spacing={1}
        >
          <Grid item xs={10}>
            <img src={matrix} />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="standard-basic"
              label="Username"
              variant="standard"
              onChange={(e) => handleUserName(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton style={{ cursor: "none" }}>
                      <AccountBoxRoundedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            // autoComplete="off"
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="standard-basic"
              label="Password"
              variant="standard"
              type={showPass === false ? "password" : "text"}
              required
              onChange={(e) => handlePassName(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={() => {
                        setShowpass(!showPass);
                      }}
                    >
                      {showPass === false ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item xs={10} style={{ marginTop: "30px" }}>
            <Button
              variant="contained"
              size="small"
              onClick={handleLogin}
              disabled={username === "" || password === ""}
            >
              Login
            </Button>

          </Grid>
          <p
            style={{ textDecorationLine: "underline", cursor: "pointer", marginTop: 20, marginRigth: 100 }}
            onClick={() => {
              forgotPass();
            }}
          >
            Forgot Password?
          </p>
        </Grid>
      </Paper>

    </div>

  );
};

export default Login;
