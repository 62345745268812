import React, { useState, useEffect } from "react";
import "../processTable.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAlteredData } from "../../reducers/OcrReducer";
import _ from "lodash";
const DynamiceTable = (props) => {
  const [currentPageData, setCurrentPageData] = useState([]);
  const Image = useSelector((state) => state.ocr.processedDataById);
  const [typed, setTyped] = useState([]);
  const alter = useSelector((state) => state.ocr.alteredData);

  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentPageData(Image.processedData[props.currentPage].data);
  }, [props.currentPage]);

  useEffect(() => {
    dispatch(
      setAlteredData({
        type: "altered",
        payload: {
          formedRows: currentPageData,
          image: Image,
          currentPage: props.currentPage,
        },
      })
    );
  }, [currentPageData]);

  function a(row, column, value) {
    let page = JSON.parse(
      JSON.stringify(Image.processedData[props.currentPage].data)
    );
    if (page.length !== 0) {
      page.forEach((rowData) => {
        if (rowData.row === row) {
          rowData.col.forEach((colData) => {
            if (colData.columnIndex === column) {
              colData.content = value;
            }
          });
        }
      });
    }

    setCurrentPageData(page);
  }

  const formRows = (x) => {
    let page = JSON.parse(
      JSON.stringify(Image.processedData[props.currentPage].data)
    );

    page.forEach((element) => {
      let row = x.insertRow(element.row);
      row.setAttribute("id", element.row);
      row.setAttribute("class", "textFormat");

      element.col.forEach((data) => {
        var cell = row.insertCell(data.columnIndex);
        // cell.addEventListener("input", function (e) {
        //   _.debounce((a, 500));
        //   // debounce(formed(element.row, data.columnIndex, cell.innerHTML), 500);
        // });
        cell.addEventListener(
          "input",
          _.debounce(() => {
            a(element.row, data.columnIndex, cell.innerHTML);
          }, 1000)
        );
        cell.setAttribute("id", data.columnIndex);
        cell.setAttribute("contentEditable", "true");
        // cell.setAttribute(."oninput", "calculate(`${data.columnIndex}`)");
        // cell.cell.onkeyup = (e) => {
        //   getTextFromTyped(e, element.row, data.columnIndex);

        // };

        cell.onpaste = (e) => {
          var clipboardData, pastedData;

          // Stop data actually being pasted into div
          e.stopPropagation();
          e.preventDefault();
          // Get pasted data via clipboard API
          clipboardData = e.clipboardData || window.clipboardData;
          pastedData = clipboardData.getData("Text");

          // Do whatever with pasteddata
          page.forEach((row) => {
            if (row.row === element.row) {
              row.col.forEach((col) => {
                if (col.columnIndex === data.columnIndex) {
                  col.content = pastedData;
                }
              });
            }
          });
          // cell.innerHTML = pastedData;
          setCurrentPageData(page);
        };
        cell.innerHTML = data.content;
      });
    });
  };
  useEffect(() => {
    var x = document.getElementById("tab").getElementsByTagName("tbody")[0];
    var rowsLength = x.rows.length;
    if (rowsLength === 0) {
      formRows(x);
    } else {
      var tableHeaderRowCount = 0;
      var table = document
        .getElementById("tab")
        .getElementsByTagName("tbody")[0];
      var rowCount = table.rows.length;
      for (var i = tableHeaderRowCount; i < rowCount; i++) {
        table.deleteRow(tableHeaderRowCount);
      }
      formRows(x);
    }
  }, [Image.processedData[props.currentPage].data]);

  return (
    <table id="tab">
      <tbody></tbody>
    </table>
  );
};

export default DynamiceTable;
