import MiniDrawer from "./components/sidebar";
import CropView from "./components/CropView";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import ForgotPassword from "./components/ForgotPassword";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>
        <Route exact path="/home" element={<MiniDrawer />}></Route>
        <Route exact path="/Home" element={<MiniDrawer />}></Route>
        <Route exact path="/Crop" element={<MiniDrawer />}></Route>
        <Route exact path="/processedData" element={<MiniDrawer />}></Route>
        <Route exact path="/cropView" element={<CropView />} />
        <Route exact path="/Users" element={<MiniDrawer />}></Route>
        <Route exact path="/forgotpassword" element={<ForgotPassword />}></Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
