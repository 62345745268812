import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Badge, Chip } from "@mui/material";

const FullScreenLoader = (props) => {
  return (
    <div>
      <Backdrop
        sx={{
          color: "#FFFFFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        open={props.open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        {props.value !== undefined && props.value !== 0 && (
          <Badge
            badgeContent={`${props.value}%`}
            color="primary"
            style={{ marginTop: "30px", marginLeft: "5px" }}
          ></Badge>
        )}
        {props.text !== undefined && (
          <Chip
            label={props.text}
            variant="outlined"
            sx={{ mt: 1, border: "1px solid white", color: "white" }}
          />
        )}
      </Backdrop>
    </div>
  );
};

export default FullScreenLoader;
