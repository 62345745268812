import React from "react";
import { useNavigate } from "react-router-dom";
import Fab from '@mui/material/Fab';
import CachedIcon from '@mui/icons-material/Cached';


const NotFound = () => {
    let navigate = useNavigate()
    return(
        <div style={{display:"flex",flexDirection:"column",flex:1,justifyContent:"center",alignItems:"center",height:'100vh'}}>
            <h3>The Requested Url Was Not Found !</h3>
            {/* <button onClick={() => {navigate('/')}}> Reload</button> */}
            <Fab variant="extended" color="primary" onClick={() => {navigate('/')}}>
        <CachedIcon sx={{ mr: 1 }} />
        Reload
      </Fab>
        </div>
    )
}

export default NotFound