import React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import "cropperjs/dist/cropper.css";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Tooltip,
  Paper,
  Toolbar,
  TextField,
  Divider,
  Chip,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogContent, DialogContentText } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SelectSearch from "./CommonUtils/SelectSearch";
import Drawer from "@mui/material/Drawer";

import {
  getTemplates,
  getImageList,
  getProcessed,
  setProcessedDataById,
  setFileType,
} from "../reducers/OcrReducer";
import { useSelector, useDispatch } from "react-redux";
import {
  getApiTemplates,
  getTemplatesList,
  OcrFileUpload,
  getProcessedData,
  getProcessedDataById,
  DeleteDataById,
  download,
} from "../actions/OcrAction";
import * as FileSaver from "file-saver";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FullScreenLoader from "./CommonUtils/FullScreenLoader";
import AlertBar from "./CommonUtils/AlertBar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FormTable from "./CommonUtils/FormTable";
import FormModal from "./CommonUtils/FormModal";
import { Report } from "@mui/icons-material";
const BASE_URL = process.env.REACT_APP_BASE_URL;
var apiTimeout = null;

function Home() {
  const createData = (name, calories, status, filename) => {
    return { name, calories, status, filename };
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [processedFiles, setProcessedFiles] = useState([]);
  const [allPlaceHolder, setAllPlaceHolder] = useState([
    "Country",
    "Template",
    "Filename",
  ]);
  const [allPlaceIndex, setAllPlaceIndex] = useState(0);
  const [selectedClient, setSelectedClient] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [Selectedtemplate, setSelectedTemplate] = useState("");
  const [master, setMaster] = useState({});
  const [template, setTemplate] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [clientId, setClientId] = useState("");
  const [templateId, setTemplateId] = useState("");
  const [openModal, setOpen] = useState(false);
  const MasterData = useSelector((state) => state.ocr.templates);
  const AllFiles = useSelector((state) => state.ocr.processedData);
  const [modalInput, setModalInput] = useState([]);
  const [getAllFiles, setAllFiles] = useState([]);
  const [allRows, setAllRows] = useState([]);

  const [filteredRows, setFilteredRows] = useState([]);
  const [typeOfFilter, setTypeOfFilter] = useState("");
  const [openNewFile, setOpenNewFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [deleteFileName, setDeleteFileName] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [selectedTemplateList, setSelectedTemplateList] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [fileList, setFileList] = useState([]);
  const [selectedFileList, setSelectedFileList] = useState("");

  var role = localStorage.getItem('role')
  // useEffect(() => {
  //   do {
  //     setAllPlaceIndex(allPlaceIndex + 1);
  //   } while (allPlaceIndex < allPlaceHolder.length);
  // }, []);

  useEffect(async () => {
    let template = await getApiTemplates({
      url: `${BASE_URL}` + `getMasterData`,
    });

    dispatch(getTemplates(template));
    let processed = await getProcessedData({
      url: `${BASE_URL}` + `home/getProcessedData`,
    });
    dispatch(getProcessed(processed));
  }, []);

  const ProcessFile = async () => {
    apiTimeout = setTimeout(loadData, 3000);
    let processed = await getProcessedData({
      url: `${BASE_URL}` + `home/getProcessedData`,
    });
    console.log(processed,'processed')
    dispatch(getProcessed(processed));
  };

  const loadData  = async()=> {
    apiTimeout = setTimeout(loadData, 3000);
    let processed = await getProcessedData({
      url: `${BASE_URL}` + `home/getProcessedData`,
    });
      console.log(processed,'processed')
      console.log(processed.data,'check')
      if(processed.data[0].status == 'Processed'){
        clearTimeout(apiTimeout)
      }
    
    
    dispatch(getProcessed(processed));
  }

  useEffect(() => {
    setMaster(MasterData);
  }, [MasterData]);

  function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  useEffect(() => {
    let country = [];
    if (
      AllFiles.data !== undefined &&
      Object.keys(AllFiles.data).length !== 0
    ) {
      AllFiles.data.forEach((data) => {
        country.push({ label: data.clientName });
      });
      let uniq = removeDuplicates(country, "label");

      setCountryList(uniq);
      setAllFiles(AllFiles.data);
    }
  }, [AllFiles]);

  useEffect(() => {
    let rows = [];

    if (getAllFiles.length !== 0) {
      getAllFiles.map((allFile) => {
        console.log(allFile,'allFile')
        rows.push({
          clientName: allFile.clientName,
          templateName: allFile.templateName,
          status: allFile.status,
          fileName: allFile.fileName,
          startTime: allFile.startTime,
          minutes: allFile.minutes,
          fileUploadedUrl:allFile.fileUploadedUrl
        });
      });
    }
    setAllRows([...rows]);
  }, [getAllFiles]);

  const handleTemplateChange = (event) => {
    if (Object.keys(master).length !== 0) {
      master.templates !== undefined &&
        master.templates.map((clientList) => {
          if (clientList.name === event.target.value) {
            setTemplateId(clientList._id);
          }
        });
    }

    setSelectedTemplate(event.target.value);
  };
  const handleClientChange = async (event) => {
    var clientId = ''
    if (Object.keys(master).length !== 0) {
      master.client !== undefined &&
        master.client.map((clientList) => {
          if (clientList.name === event.target.value) {
            setClientId(clientList._id);
            clientId = clientList._id
          }
        });
    }
    setSelectedClient(event.target.value);
    GetTemplatesData(clientId)
  };

  const GetTemplatesData = async (clientId) => {
    let templates = await getTemplatesList({
      url: `${BASE_URL}` + `getTemplates?clientId=${clientId}`,
    });
    setTemplate(templates)
  };

  const FormModalInputs = (inputValue) => {
    setTypeOfFilter(inputValue);
    setOpen(true);
    if (inputValue === "client") {
      if (Object.keys(master).length !== 0 && master.client !== undefined) {
        let opt = [];
        master.client.map((clientList) => {
          opt.push(clientList.name);
        });
        setModalInput(opt);
      }
    }
    if (inputValue === "template") {
      if (Object.keys(master).length !== 0 && master.templates !== undefined) {
        let opt = [];

        master.templates.map((templateList) => {
          opt.push(templateList.name);
        });
        setModalInput(opt);
      }
    }
    if (inputValue === "status") {
      setModalInput(["Processing", "Processed", "All"]);
    }
  };

  const handleModalclose = (selected, type) => {
    setOpen(false);
    let filtered = allRows.filter(
      (data) =>
        data[
        type === "client"
          ? "clientName"
          : type === "template"
            ? "templateName"
            : type
        ] === selected
    );
    if (filtered.length === 0) {
      setFilteredRows([]);
    } else {
      setFilteredRows([...filtered]);
    }
  };

  const fetchDataById = async (row) => {
    if (row.status === "Processing") {
      setOpenSnack(true);
      setAlertColor("warning");
      setSnackMessage("File is under process");
    } else {
      getAllFiles.forEach(async (file) => {
        if (file.fileName === row.fileName) {
          let processedById = await getProcessedDataById({
            url: `${BASE_URL}` + `getProcessedData?fileId=${file._id}`,
          });
          dispatch(setProcessedDataById(processedById));
          dispatch(setFileType({ type: "fileType", payload: file.type }));

          navigate("/Crop");
        }
      });
    }
  };

  const openUsersData = () => {
    navigate("/Users");
  }

  const DeleteFileById = (row) => {
    if (row.status === "Processing") {
      setOpenSnack(true);
      setAlertColor("warning");
      setSnackMessage("File is under process");
    } else {
      setLogoutModalOpen(true);
      setDeleteFileName(row.fileName);
    }
  };

  const deleteParticularFile = () => {
    setLogoutModalOpen(false);
    setLoading(true);

    getAllFiles.forEach(async (file) => {
      if (file.fileName === deleteFileName) {
        let deleteBy = await DeleteDataById({
          url: `${BASE_URL}` + `deleteFile?fileId=${file._id}`,
        });
        if (deleteBy.status === 200 && deleteBy.data.message === "success") {
          setOpenSnack(true);
          setLoading(false);
          setAlertColor("success");
          setSnackMessage(`${file.fileName}-Deleted`);
          ProcessFile();
        } else {
          setLoading(false);
        }
      }
    });
  };

  const DownloadReport = (row) => {
    if (row.status === "Processing") {
      setOpenSnack(true);
      setAlertColor("warning");
      setSnackMessage("File is under process");
    } else {
      setLoading(true);
      getAllFiles.forEach(async (file) => {
        if (file.fileName === row.fileName) {
          let report = await download({
            url: `${BASE_URL}` + `exportReport?fileId=${file._id}`,
          });
          if (report.status === 200) {
            setOpenSnack(true);
            setLoading(false);
            setAlertColor("success");
            setSnackMessage(`${file.fileName}-report downloaded`);

            FileSaver.saveAs(
              report.data,
              `${file.fileName}-report.${file.type === "excel" ? "xlsx" : "docx"
              }`
            );
          } else {
            setLoading(false);
          }
        }
      });
    }
  };

  const getBase64 = async (file) => {
    setOpenSnack(false);
    setSelectedFile(file);
  };
  const onChangeFilePicker = (e) => {
    getBase64(e.target.files[0]);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const saveNewFile = async () => {
    if (selectedFile !== null) {
      var formData = new FormData();
      formData.append("file", selectedFile, selectedFile.name);
      formData.append("clientId",clientId);
      formData.append("templateId",templateId)
      setLoading(true);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);

          if (percent < 100) {
            setUploadPercent(percent);
          }
        },
      };

      let result = await OcrFileUpload({
        url: `${BASE_URL}uploadFile`,
        uploadData: formData,
        config: config,
        options,
      });
      if (result.error !== undefined && result.error === true) {
        setLoading(false);
        setAlertColor("error");
        setSnackMessage("Failed To Upload The File");
        setOpenSnack(true);
      }
      if (result.status === 200) {
        setUploadPercent(100, () => {
          setTimeout(() => {
            setUploadPercent(0);
          }, 1000);
        });

        dispatch(getImageList(result.data.imageUrls));
        setLoading(false);
        setOpenSnack(true);

        setAlertColor("success");

        setSnackMessage("File Uploaded Successfully");
        ProcessFile();
        setOpenNewFile(false);
        setSelectedClient("");
        setSelectedTemplate("");
        setSelectedFile(null);
      } else {
        setLoading(false);
      }
    }
  };

  const handleCountry = (e, value) => {
    let template = [];
    let filtered = [];
    if (Object.keys(AllFiles).length) {
      AllFiles.data.forEach((data) => {
        if (data.clientName === value) {
          filtered.push(data);
          template.push({ label: data.templateName });
        }
      });
    }
    let uniq = removeDuplicates(template, "label");
    setFilteredRows(filtered);
    setTemplateList(uniq);
    setSelectedCountry(value);
  };

  const handleTemplate = (e, value) => {
    let status = [];
    let filtered = [];
    if (Object.keys(AllFiles).length) {
      AllFiles.data.forEach((data) => {
        if (
          data.clientName === selectedCountry &&
          data.templateName === value
        ) {
          filtered.push(data);
          status.push({ label: data.status });
        }
      });
    }
    let uniq = removeDuplicates(status, "label");
    setFilteredRows(filtered);
    setStatusList(uniq);
    setSelectedTemplateList(value);
  };

  const handleStatus = (e, value) => {
    let file = [];
    let filtered = [];
    if (Object.keys(AllFiles).length) {
      AllFiles.data.forEach((data) => {
        if (
          data.clientName === selectedCountry &&
          data.templateName === selectedTemplateList &&
          data.status === value
        ) {
          filtered.push(data);
          file.push({ label: data.fileName });
        }
      });
    }
    let uniq = removeDuplicates(file, "label");
    setFilteredRows(filtered);
    setFileList(uniq);
    setSelectedStatus(value);
  };

  const handleFile = (e, value) => {
    setSelectedFileList(value);
  };

  const trackFilterText = (e) => {
    let filtered = allRows.filter((o) =>
      Object.keys(o).some((k) => o[k].toLowerCase().includes(e.toLowerCase()))
    );
    setFilteredRows(filtered);
  };

  return (
    <div
      style={{
        display: "flex",
        flex: "0 1 auto",
        flexDirection: "column",
        height: "91vh",
        margin: 5,
      }}
    >
      <FullScreenLoader open={loading} value={uploadPercent} />
      <FormModal
        open={openModal}
        onClose={handleModalclose}
        title={"Filter By"}
        incoming={modalInput}
        type={typeOfFilter}
      />
      <AlertBar
        openSnack={openSnack}
        handleClose={handleClose}
        alertColor={alertColor}
        snackMessage={snackMessage}
      />
      <Dialog
        onClose={() => {
          setLogoutModalOpen(false);
        }}
        open={logoutModalOpen}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
              columnGap: 3,
            }}
          >
            <NotificationsActiveIcon color="warning" />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{`Are you sure you want to delete ${deleteFileName}?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLogoutModalOpen(false);
            }}
          >
            No
          </Button>
          <Button onClick={deleteParticularFile}>Yes</Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ flexGrow: 2 }}>
        <Drawer
          anchor={"right"}
          open={openNewFile}
          onClose={() => {
            setOpenNewFile(false);
          }}
        >
          <Box sx={{ width: 380 }}>
            <div>
              <h1>hi</h1>
            </div>
            <Grid
              style={{ marginTop: 40 }}
              container
              xs={12}
              display={"flex"}
              direction={"column"}
              spacing={1}
              sx={{ p: 0.1 }}
            >
              <Grid item sx={{ m: 1 }}>
                <Chip
                  label="Upload New File"
                  variant="contained"
                  color="primary"
                />
              </Grid>
              <Divider />
              <Grid item xs={4}>
                <FormControl sx={{ m: 1, width: "95%" }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={selectedClient}
                    onChange={handleClientChange}
                    label="Client"
                  >
                    {Object.keys(master).length !== 0 &&
                      master.client !== undefined &&
                      master.client.map((clientList) => {
                        return (
                          <MenuItem value={clientList.name}>
                            {clientList.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl sx={{ m: 1, width: "95%" }}>
                  <InputLabel id="demo-simple-select-autowidth-label">
                    Template
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={Selectedtemplate}
                    onChange={handleTemplateChange}
                    label="Template"
                  >
                    {Object.keys(template).length !== 0 &&
                      template.templates !== undefined &&
                      template.templates.map((templateList) => {
                        return (
                          <MenuItem value={templateList.name}>
                            {`${templateList.name} | Language: ${templateList.language}`}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl sx={{ m: 1, width: "95%" }}>
                  <Tooltip title={"File Upload"} arrow placement="top">
                    <Button
                      variant="outlined"
                      component="label"
                      style={{
                        height: "80px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      disabled={
                        selectedClient === "" || Selectedtemplate === ""
                      }
                    >
                      <CloudUploadIcon />
                      {selectedFile !== null ? (
                        <span>{selectedFile.name}</span>
                      ) : null}

                      <input
                        type="file"
                        hidden
                        onChange={(e) => {
                          onChangeFilePicker(e);
                        }}
                      />
                    </Button>
                  </Tooltip>
                </FormControl>
              </Grid>
              <Grid item xs={4} sx={{ m: 1, width: "98%" }}>
                <Button variant="contained" fullWidth onClick={saveNewFile}>
                  {" "}
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Drawer>

        {role == 'viewer' && (
          <Grid container spacing={3} sx={{ p: 3 }} justifyContent={"center"}>
            <Grid item xs={14}>
              <Paper
                elevation={1}
                sx={{
                  width: "18%",
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // border: "1px solid blue",
                }}
              >
                <h5 style={{ letterSpacing: 1, color: "blue" }}>
                  PROCESSED FILES
                </h5>
              </Paper>
            </Grid>

          </Grid>
        )}
        {role == 'admin' && (
          <Grid container spacing={3} sx={{ p: 3 }} justifyContent={"center"}>
            <Grid item xs={8}>
              <Paper
                elevation={1}
                sx={{
                  width: "18%",
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // border: "1px solid blue",
                }}
              >
                <h5 style={{ letterSpacing: 1, color: "blue" }}>
                  PROCESSED FILES
                </h5>
              </Paper>
            </Grid>

            <Grid
              item
              xs={2}
              display={"flex"}
              justifyContent={"flex-end"}
            // marginLeft={10}
            >
              <Button
                variant="contained"
                onClick={() => {
                  openUsersData();
                }}
              >
                {" "}
                Users
              </Button>
            </Grid>

            <Grid
              item
              xs={2}
              display={"flex"}
            // justifyContent={"flex-end"}
            // sx={{ m: 1 }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setOpenNewFile(true);
                }}
              >
                {" "}
                Add new file
              </Button>
            </Grid>


          </Grid>
        )}

        {role == 'editor' && (
          <Grid container spacing={2} sx={{ p: 2 }} justifyContent={"center"}>
            <Grid item xs={8}>
              <Paper
                elevation={1}
                sx={{
                  width: "18%",
                  height: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // border: "1px solid blue",
                }}
              >
                <h5 style={{ letterSpacing: 1, color: "blue" }}>
                  PROCESSED FILES
                </h5>
              </Paper>
            </Grid>

            <Grid
              item
              xs={4}
              display={"flex"}
              justifyContent={"flex-end"}
            // sx={{ m: 1 }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setOpenNewFile(true);
                }}
              >
                {" "}
                Add new file
              </Button>
            </Grid>


          </Grid>
        )}


        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          display={"flex"}
          direction={"column"}
          flex={1}
        >
          <Paper elevation={5}>
            {/* <Grid
              item
              display={"flex"}
              justifyContent={"flex-end"}
              sx={{ m: 2 }}
            >
              <Button variant="outlined">Reset Filter</Button>
            </Grid> */}

            <Grid
              container
              xs={12}
              spacing={2}
              display={"flex"}
              direction={"row"}
              alignItems={"center"}
              sx={{ m: 0.2, mb: 1 }}
            >
              <Grid item>
                <SelectSearch
                  placeholder={"Country"}
                  opt={countryList}
                  setLabel={handleCountry}
                  selected={selectedCountry}
                />
              </Grid>
              <Grid item>
                <SelectSearch
                  placeholder={"Template"}
                  opt={templateList}
                  setLabel={handleTemplate}
                  selected={selectedTemplateList}
                />
              </Grid>
              <Grid item>
                <SelectSearch
                  placeholder={"Status"}
                  opt={statusList}
                  setLabel={handleStatus}
                  selected={selectedStatus}
                />
              </Grid>
              <Grid item>
                <SelectSearch
                  placeholder={"File-name"}
                  opt={fileList}
                  setLabel={handleFile}
                  selected={selectedFileList}
                />
              </Grid>
              <Grid item display={"flex"} justifyContent={"flex-end"}>
                <Button
                  variant="outlined"
                  size="small"
                  color="warning"
                  onClick={() => {
                    setFilteredRows([]);
                    setTemplateList([]);
                    setStatusList([]);
                    setFileList([]);
                    setSelectedCountry("");
                    setSelectedFileList("");
                    setSelectedTemplateList("");
                    setSelectedStatus("");
                  }}
                  disabled={filteredRows.length === 0}
                >
                  Reset Filter
                </Button>
              </Grid>
            </Grid>

            <FormTable
              rows={filteredRows.length === 0 ? allRows : filteredRows}
              fetchDataById={fetchDataById}
              DeleteFileById={DeleteFileById}
              downloadReport={DownloadReport}
            />
          </Paper>
        </Grid>
      </Box>
    </div>
  );
}

export default Home;
