import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { persistor, store } from "./Store";
import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./components/ErrorBoundry";

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
