import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Button, Paper, Box } from "@mui/material";
import MultiCrops from "react-multi-crops";
import { OcrFileTranslateText } from "../actions/OcrAction";
import FullScreenLoader from "./CommonUtils/FullScreenLoader";
const BaseUrl = process.env.REACT_APP_BASE_URL;

const CropView = () => {
  const full = useSelector((state) => state.ocr);
  const [images, setImages] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);

  const [result, setResult] = useState([]);
  const changeCoordinate = (coordinate, index, coordinates) => {
    setCoordinates(coordinates);
  };
  const deleteCoordinate = (coordinate, index, coordinates) => {
    setCoordinates(coordinates);
  };

  useEffect(() => {
    let allOcrValues = JSON.parse(localStorage.getItem("ocr"));

    // let processedDataForCurrentFile =
    //   allOcrValues.processedDataById.originalImageUploadedUrls[allOcrValues.currentPage]
    //     .url;
    let processedDataForCurrentFile =
      allOcrValues.processedDataById.processedData[allOcrValues.currentPage]
        .imageUrl;



    console.log(processedDataForCurrentFile, allOcrValues.currentPage, 'secondPage')

    setAllData(
      allOcrValues.processedDataById.processedData[allOcrValues.currentPage]
        .data
    );
    setImages(processedDataForCurrentFile);
  }, []);

  const extractData = async () => {
    let cropped = {
      photoUrl: images,
      coordinates: coordinates,
    };
    setLoading(true);

    let translatedResult = await OcrFileTranslateText({
      url: BaseUrl + "getTextfromImage",
      cropped,
    });
    if (translatedResult.status === 200) {
      setResult(translatedResult.data.result);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getText = () => {
    let text = [];
    if (result.length !== 0) {
      result.forEach((data) => {
        text.push(
          <Box sx={{ p: 2, border: "1px dashed grey" }}>
            <Grid container spacing={2} display={"flex"} direction={"row"}>
              <Grid item xs={6}>
                <span>
                  <b>Original Text</b>
                  {`- ${data.originalText}`}
                </span>
              </Grid>
              <Grid item xs={6}>
                <span>
                  <b>Translated Text </b>
                  {`- ${data.translatedText}`}
                </span>
              </Grid>
            </Grid>
          </Box>
        );
      });
    }
    return text;
  };

  return (
    <Grid
      container
      xs={12}
      sx={{ border: "1px solid blue", m: 0.5, width: "99vw", height: "99vh" }}
    >
      <Grid item xs={8}>
        <Grid container display={"flex"} direction={"column"}>
          <Grid item>
            <FullScreenLoader
              open={loading}
              text={"Fetching Extracted Data "}
            />{" "}
            <MultiCrops
              src={images}
              style={{ height: "auto", width: "auto" }}
              coordinates={coordinates}
              onDraw={changeCoordinate}
              onDelete={deleteCoordinate}
            />{" "}
          </Grid>
          <Grid item sx={{ ml: 7 }}>
            {" "}
            <Button variant="contained" onClick={extractData}>
              Extract
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
        display={"flex"}
        direction={"column"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        {result.length !== 0 && (
          <Paper
            elevation={5}
            sx={{ p: 1, mr: 1, height: "90vh", overflowY: "scroll", mt: 2 }}
          >
            {getText()}
          </Paper>
        )}
      </Grid>
    </Grid>

  );
};

export default CropView;
