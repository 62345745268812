import React, { useState, useEffect, useRef } from "react";
import { Button, Grid, Tooltip, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import AlertBar from "./CommonUtils/AlertBar";

import "./processTable.css";

import { saveNewRows } from "../actions/OcrAction";

import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@mui/icons-material/RemoveCircleOutlineRounded";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import DynamiceTable from "./CommonUtils/DynamicTable";
import { setAlteredData } from "../reducers/OcrReducer";
import JoditEditor from "jodit-react";

const BaseUrl = process.env.REACT_APP_BASE_URL;

const ProcessedData = () => {
  const dispatch = useDispatch();
  const [allFiles, setAllFiles] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [formedRows, setFormedRow] = useState([]);
  const [pageNo, setPageNo] = useState(0);

  //Jodit-editor
  const editor = useRef(null);
  const [content, setContent] = useState([]);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    height: "550px",
    width: "100%",
    enableDragAndDropFileToEditor: true,
    buttons: [
      "source",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "ul",
      "ol",
      "|",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "image",
      "table",
      "link",
      "|",
      "left",
      "center",
      "right",
      "justify",
      "|",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "fullsize",
    ],

    removeButtons: [
      "source",
      "bold",
      "italic",
      "underline",
      "ul",
      "ol",
      "font",
      "fontsize",
      "brush",
      "paragraph",
      "image",
      "table",
      "link",
      "left",
      "center",
      "justify",
      "right",
      "undo",
      "redo",
      "hr",
      "eraser",
      "fullsize",
      "dots",
      "copyformat",
    ],

    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: true,
    toolbarSticky: true,
    askBeforePasteHTML: false,
    toolbar: false,
  };

  const currentPage = useSelector((state) => state.ocr.currentPage);
  const cellWiseData = useSelector((state) => state.ocr.cellWiseData);
  const Image = useSelector((state) => state.ocr.processedDataById);
  const alter = useSelector((state) => state.ocr.alteredData);
  const FileType = useSelector((state) => state.ocr.fileType);

  useEffect(() => {
    dispatch(
      setAlteredData({
        type: "altered",
        payload: {
          formedRows: formedRows,
          image: Image,
          currentPage: currentPage,
        },
      })
    );
  }, [formedRows]);

  useEffect(() => {
    if (
      cellWiseData !== undefined &&
      cellWiseData.pageNo !== undefined &&
      cellWiseData.pageNo === currentPage + 1
    ) {
      setAllFiles(cellWiseData.data);
      setPageNo(cellWiseData.pageNo);
      setFormedRow(cellWiseData.data);
      dispatch(
        setAlteredData({
          type: "altered",
          payload: {
            formedRows: cellWiseData.data,
            image: Image,
            currentPage: currentPage,
          },
        })
      );
    } else {
      let fileData = fetchCurrentPage();
      let indexData = fileData.length !== 0 ? fileData[currentPage] : [];

      if (indexData !== undefined && indexData.data !== undefined) {
        setContent(indexData.data.toString());
        setFormedRow(indexData.data);
        setAllFiles(indexData.data);
        setPageNo(indexData.pageNo);
        dispatch(
          setAlteredData({
            type: "altered",
            payload: {
              formedRows: indexData.data,
              image: Image,
              currentPage: currentPage,
            },
          })
        );
      }
    }
  }, [cellWiseData, currentPage]);

  const fetchCurrentPage = () => {
    let getFileData = [];
    if (Image !== undefined && Object.keys(Image).length !== 0) {
      getFileData = Image["processedData"];
    }
    return getFileData;
  };

  const handleClose = () => {
    setOpenSnack(false);
  };

  const addrowToBottom = () => {
    let fileData = fetchCurrentPage();
    let indexData = fileData.length !== 0 ? fileData[currentPage] : [];
    var colCount = indexData.columnCount;

    let lastrow =
      formedRows.length === 0
        ? indexData.data[indexData.data.length - 1]
        : formedRows[formedRows.length - 1];
    // let parsed =
    //   formedRows.length === 0
    //     ? JSON.parse(JSON.stringify(allFiles))
    //     : JSON.parse(JSON.stringify(formedRows));
    let parsed = JSON.parse(JSON.stringify(alter));

    let formColumns = [];
    for (let i = 0; i < colCount; i++) {
      formColumns.push({
        columnIndex: i,
        content: "",
      });
    }
    parsed.push({ row: lastrow.row + 1, col: formColumns, addedRow: true });

    // let tabl = document.getElementById("tab").getElementsByTagName("tbody")[0];
    // let row = tabl.insertRow(parsed[parsed.length - 1].row);

    // parsed[parsed.length - 1].col.forEach((data) => {
    //   var cell = row.insertCell(data.columnIndex);

    //   cell.innerHTML = data.content;
    // });

    console.log(parsed, "parsedddddddddddd");

    setFormedRow([...parsed]);
  };

  const addrowTotop = () => {
    let fileData = fetchCurrentPage();
    let indexData = fileData.length !== 0 ? fileData[currentPage] : [];
    var colCount = indexData.columnCount;
    let parsed = JSON.parse(JSON.stringify(alter));

    parsed.forEach((data, index) => {
      data.row = index + 1;
    });
    let formColumns = [];
    for (let i = 0; i < colCount; i++) {
      formColumns.push({
        columnIndex: i,
        content: "",
      });
    }
    parsed.unshift({ row: 0, col: formColumns, addedRow: true });
    // let tabl = document.getElementById("tab").getElementsByTagName("tbody")[0];
    // let row = tabl.insertRow(parsed[0].row);
    // parsed[0].col.forEach((data) => {
    //   var cell = row.insertCell(data.columnIndex);
    //   cell.setAttribute("contentEditable", "true");
    //   cell.onpaste = (e) => {
    //     var clipboardData, pastedData;

    //     // Stop data actually being pasted into div
    //     e.stopPropagation();
    //     e.preventDefault();

    //     // Get pasted data via clipboard API
    //     clipboardData = e.clipboardData || window.clipboardData;
    //     pastedData = clipboardData.getData("Text");

    //   };

    //   cell.innerHTML = data.content;
    // });
    setFormedRow([...parsed]);
  };

  const DeleteRowsFromTop = () => {
    let findFirstRow = formedRows.findIndex(
      (data) => data.row === 0 && data.addedRow === true
    );
    if (findFirstRow !== -1) {
      let tabl = document
        .getElementById("tab")
        .getElementsByTagName("tbody")[0];
      tabl.deleteRow(0);

      let removeFirstRow = formedRows.slice(
        findFirstRow + 1,
        formedRows.length
      );

      let arrangeData = [];
      for (let i = 0; i < removeFirstRow.length; i++) {
        arrangeData.push({
          row: removeFirstRow[i].row - 1,
          col: removeFirstRow[i].col,
          addedRow: removeFirstRow[i].addedRow,
        });
      }

      setFormedRow([...arrangeData]);
    }
  };

  const saveRows = async () => {
    if (FileType === "excel" && alter.length !== 0) {
      console.log(Image, "ooppopp");
      let saveData = {
        clientId: Image.clientId,
        templateId: Image.templateId,
        fileName: Image.fileName,
        pageNo: pageNo,
        data: [...alter],
      };
      let result = await saveNewRows({
        url: `${BaseUrl}updateTableData`,
        input: saveData,
      });
      if (result.data.message === "success") {
        setSnackMessage("Formatted data saved successfully");
        setAlertColor("success");
        setOpenSnack(true);
      } else {
        setSnackMessage("Formatted data not saved");
        setAlertColor("error");
        setOpenSnack(true);
      }
    }
    if (FileType === "docx" && content) {
      let saveDat = {
        clientId: Image.clientId,
        templateId: Image.templateId,
        fileName: Image.fileName,
        pageNo: pageNo,
        data: content,
      };
      let result = await saveNewRows({
        url: `${BaseUrl}updateTableData`,
        input: saveDat,
      });
      if (result.data.message === "success") {
        setSnackMessage("New content saved successfully");
        setAlertColor("success");
        setOpenSnack(true);
      } else {
        setSnackMessage("Content not saved");
        setAlertColor("error");
        setOpenSnack(true);
      }
    }
  };

  const changeContent = (con) => {
    // console.log(con, "cont");
    console.log(content);
    // setContent(con);
  };

  const DeleteRowsFromBottom = () => {
    let findLastRow = formedRows.findIndex(
      (data) => data.row === formedRows.length - 1 && data.addedRow === true
    );
    if (findLastRow !== -1) {
      let tabl = document
        .getElementById("tab")
        .getElementsByTagName("tbody")[0];
      tabl.deleteRow(formedRows.length - 1);
      let removeFirstRow = formedRows.filter(
        (data) => data.row !== findLastRow
      );
      setFormedRow([...removeFirstRow]);
    }
  };


  var role = localStorage.getItem('role')

  return (
    <Box style={{ width: "98%", height: "95%" }}>
      <AlertBar
        openSnack={openSnack}
        handleClose={handleClose}
        alertColor={alertColor}
        snackMessage={snackMessage}
      />
      <Grid container display={"flex"} flexDirection={"column"}>
        <Grid item>
          <Grid
            container
            display={"flex"}
            justifyContent={"flex-end"}
            spacing={2}
          >
            {FileType === "excel" && (
              <>
                {" "}
                <Grid item>
                  <Tooltip title="Add row from top">
                    <AddCircleOutlineRoundedIcon
                      onClick={addrowTotop}
                      sx={{ cursor: "pointer", color: "blue" }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>Top</Grid>
                <Grid item sx={{ mb: 0.5 }}>
                  <Tooltip title="Remove row from top">
                    <RemoveCircleOutlineRoundedIcon
                      sx={{ cursor: "pointer", color: "blue" }}
                      onClick={DeleteRowsFromTop}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Add row to bottom">
                    <AddCircleOutlineRoundedIcon
                      onClick={addrowToBottom}
                      sx={{ cursor: "pointer", color: "blue" }}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>Bottom</Grid>
                <Grid item sx={{ mb: 0.5 }}>
                  <Tooltip title="Remove row from bottom">
                    <RemoveCircleOutlineRoundedIcon
                      sx={{ cursor: "pointer", color: "blue" }}
                      onClick={DeleteRowsFromBottom}
                    />
                  </Tooltip>
                </Grid>
              </>
            )}
            {role !== 'viewer' && (
              <Grid item sx={{ mb: 0.5 }}>
                <Button
                  variant="contained"
                  sx={{ fontSize: 10 }}
                  color="success"
                  size="small"
                  endIcon={<BeenhereIcon fontSize="5" />}
                  onClick={saveRows}
                >
                  save
                </Button>
              </Grid>
            )}

          </Grid>
        </Grid>
        {FileType === "excel" && (
          <Grid
            item
            sx={{
              width: "100%",
              height: "570px",
              overflowX: "scroll",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                width: "680px",
                height: "95%",
              }}
            >
              <DynamiceTable data={allFiles} currentPage={currentPage} />
            </div>
          </Grid>
        )}
        {FileType === "docx" && (
          <Grid item>
            <div
              style={{
                width: "700px",
                height: "575px",
                overflowX: "scroll",
              }}
            >
              <JoditEditor
                ref={editor}
                value={`${content}`}
                config={config}
                tabIndex={1} // tabIndex of textarea
                onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              // onChange={(newContent) => {
              //   console.log(typeof newContent);
              //   changeContent(newContent);
              //   // setContent(newContent);
              // }}
              />
              {/* <TextareaAutosize
                style={{
                  width: "100%",
                  backgroundColor: "#dbe3e6",
                  height: "100%",
                  border: "1px solid blue",
                }}
                // defaultValue={`${allFiles}`}
                defaultValue={`${[
                  "hi hello \n",
                  "maris \n",
                  "nice to hear \n",
                ]}`}
              /> */}
            </div>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ProcessedData;
