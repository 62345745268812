import { createSlice } from "@reduxjs/toolkit";
import { setupAxios } from "../utils.js";
export const counterSlice = createSlice({
  name: "ocr",
  initialState: {
    value: 0,
    client: [],
    templates: {},
    ImageList: [],
    currentFile: {},
    processedData: {},
    cellWiseData: {},
    currentPage: 0,
    token: '',
    role: '',
    processedDataById: [],
    alteredData: [],
    alteredCheck: false,
    isLoggedInUser: false,
    loggedInUser: {},
    fileType: null,
    disablePre: true,
    disableNext: false,
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1;
    },
    setFileType: (state, action) => {
      state.fileType = action.payload.payload;
    },

    getTemplates: (state, action) => {
      state.templates = action.payload;
    },
    getImageList: (state, action) => {
      state.ImageList = action.payload;
    },
    setCurrentFile: (state, action) => {
      state.currentFile = action.payload;
    },
    getProcessed: (state, action) => {
      state.processedData = action.payload;
    },
    getCellWiseProcessedData: (state, action) => {
      state.cellWiseData = action.payload.translatedData[0];
    },
    setCurrentPageInc: (state, action) => {
      state.currentPage = action.payload.payload;
    },
    setProcessedDataById: (state, action) => {
      state.processedDataById = action.payload.data[0];
    },
    setAlteredData: (state, action) => {
      let { image, currentPage, formedRows } = action.payload.payload;
      let parsed = JSON.parse(JSON.stringify(image.processedData));
      let find = parsed[currentPage];
      find["data"] = formedRows;

      let data = {
        ...image,
        processedData: [...parsed],
      };
      state.processedDataById = data;
      state.alteredData = action.payload.payload.formedRows;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
      state.token = action.payload.access_token
      state.role = action.payload.role
      setupAxios(action.payload.access_token)
      state.isLoggedInUser = true;
    },
    setLogout: (state, action) => {
      state.loggedInUser = {};
      state.isLoggedInUser = action.payload.payload;
    },
    clearPageIndex: (state, action) => {
      state.currentPage = 0;
    },
    setHandleDisablePre: (state, action) => {
      state.disablePre = action.payload.payload;
    },
    setHandleDisableNext: (state, action) => {
      state.disableNext = action.payload.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  increment,
  decrement,
  incrementByAmount,
  getTemplates,
  getImageList,
  setCurrentFile,
  getProcessed,
  getCellWiseProcessedData,
  setCurrentPageInc,
  setProcessedDataById,
  setAlteredData,
  setLoggedInUser,
  setLogout,
  setFileType,
  clearPageIndex,
  setHandleDisablePre,
  setHandleDisableNext,
} = counterSlice.actions;

export default counterSlice.reducer;
