import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Dialog,
  List,
  Avatar,
  ListItemAvatar,
  ListItemText,
  ListItem,
} from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DoneIcon from "@mui/icons-material/Done";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import { blue } from "@mui/material/colors";

const FormModal = (props) => {
  const handleListItemClick = (income) => {
    props.onClose(income, props.type);
  };
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <List sx={{ pt: 0 }}>
        {props.incoming.map((income) => (
          <ListItem
            button
            onClick={() => handleListItemClick(income)}
            key={income}
          >
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                {income === "Processing" ? (
                  <DoNotDisturbIcon color="warning" />
                ) : income === "Processed" ? (
                  <DoneIcon color="success" />
                ) : (
                  <CallMergeIcon />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={income} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default FormModal;
