import React, { useState, useEffect } from "react";
import { Slide, Alert, Snackbar } from "@mui/material";

const AlertBar = (props) => {
  const [transition, setTransition] = useState(undefined);

  useEffect(() => {
    setTransition(() => TransitionLeft);
  }, [props]);
  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }
  const vertical = "top";
  const horizontal = "right";
  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={props.openSnack}
      autoHideDuration={6000}
      onClose={props.handleClose}
      TransitionComponent={transition}
      key={"top" + "right"}
    >
      <Alert
        onClose={props.handleClose}
        severity={props.alertColor}
        sx={{ width: "100%" }}
      >
        {props.snackMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
