import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import { Typography, Tooltip, Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import {
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import Home from "./Home";
import Crop from "./Crop";
import ProcessedData from "./proccessedData";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { setLogout } from "../reducers/OcrReducer";
import Users from "./Users";
import ErrorBoundary from "../components/ErrorBoundry";
import crypto from 'crypto';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);
  const [name, setname] = React.useState("");
  const user = useSelector((state) => state.ocr.loggedInUser);
  // const decode = (data) => {
  //   const decipher = crypto.createDecipheriv("aes-256-cbc", "6fa979f20126cb08aa645a8f495f6d85", "7777777a72ddc2f1");

  //   let decryptedData = decipher.update(data, "binary", "utf-8");

  //   decryptedData += decipher.final("utf-8");

  //   return decryptedData
  // }
  const displayName = user.userName;
  const cap = displayName.charAt(0).toUpperCase() + displayName.slice(1);
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('access_token')
    localStorage.removeItem('token')
    dispatch(setLogout({ type: "logout", payload: false }));
    navigate("/");
  };
  const handleClose = () => {
    setLogoutModalOpen(false);
  };
  const openExit = () => {
    setname(user.userName);
    setLogoutModalOpen(true);
  };

  var role = localStorage.getItem('role')

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            // onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          // style={{}}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            OCR Invoice Processing
          </Typography>

        </Toolbar>
      </AppBar>

      {/* <Router> */}
      <div style={{ display: "flex" }}>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <Link to="/home" style={{ textDecoration: "none" }}>
              <ListItem button>
                <ListItemIcon>
                  <Tooltip title="File-upload" placement="right">
                    {/* <FileUploadIcon /> */}
                    <HomeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText primary="File Upload" />
              </ListItem>
            </Link>
          </List>
          <Divider />
          <Grid container alignItems={"flex-end"} style={{ flex: 1 }}>
            <List>
              <Divider />
              <Link to="" style={{ textDecoration: "none" }} onClick={openExit}>
                <ListItem button>
                  <ListItemIcon onClick={openExit}>
                    <Tooltip title="logout" placement="right">
                      <PowerSettingsNewIcon />
                    </Tooltip>
                  </ListItemIcon>
                </ListItem>
              </Link>
            </List>
          </Grid>
        </Drawer>
      </div>
      <Dialog
        onClose={() => {
          setLogoutModalOpen(false);
        }}
        open={logoutModalOpen}
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
              columnGap: 3,
            }}
          >
            <NotificationsActiveIcon color="warning" />
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{`${cap}, Are you sure you want to logout?`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleLogout}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Box component="main" sx={{ flex: 1 }}>

        <DrawerHeader />
        {location.pathname === "/home" && <Home />}
        {role === 'admin' && (
          location.pathname === "/Users" && <Users />
        )}
        {role !== 'admin' && location.pathname === "/Users" && (
          <ErrorBoundary />
        )}

        {location.pathname === "/Crop" && <Crop />}
        {location.pathname === "/processedData" && <ProcessedData />}

      </Box>
    </Box>
  );
}
