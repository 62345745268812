import React, { useEffect, useState } from 'react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { useTheme } from "@mui/material/styles";
import TablePagination from "@mui/material/TablePagination";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import {
    Paper,
    Box
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    Button,
    Modal,
    Form,
    Dropdown,
    Grid,
    ModalContent,
    Input,
    Label,
    Icon
} from "semantic-ui-react";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import validator from 'validator';
import crypto from 'crypto';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import PausePresentationIcon from '@mui/icons-material/PausePresentation';
const Users = () => {

    const [user, setUser] = useState([]);
    const [open, setOpen] = useState(false);
    const [modalopen, setModalOpen] = useState(false);
    const [delModalopen, setDelModalOpen] = useState(false);
    const [changeModalopen, setChangeModalOpen] = useState(false);
    const [iserror, setIserror] = useState(false);
    const Token = useSelector((state) => state.ocr.token);
    const [errorMessage, setErrorMessage] = useState('')
    const [oldErrorMessage, setOldErrorMessage] = useState('')
    const [newErrorMessage, setNewErrorMessage] = useState('')
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [name, setName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [mobileNo, setMobileNo] = useState('');
    const [role, setRole] = useState('');
    const [country, setCountry] = useState('');
    const [newUser, setNewUser] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [code, setCode] = useState('');
    const [rowData, setRowData] = useState({});

    const navigate = useNavigate();

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    var roles = [
        {
            'key': "editor",
            'value': "editor",
            'text': "editor"
        },
        {
            'key': "viewer",
            'value': "viewer",
            'text': "viewer"
        }
    ]

    var countryList = [
        {
            'key': "Egypt",
            'value': "Egypt",
            'text': "Egypt"
        },
        {
            'key': "India",
            'value': "India",
            'text': "India"
        }
    ]

    const validateEmail = (email) => {
        const re = /^((?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\]))$/;
        return re.test(String(email).toLowerCase());
    }

    const validateNumber = (num) => {
        if (num.length === 10) {
            return true
        }
        else {
            return false
        }
    }

    const headers = {
        'Authorization': 'Bearer ' + Token
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - user.length) : 0;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const rowClick = (row) => {
        // props.fetchDataById(row);
    };

    useEffect(() => {
        axios.get(`${BASE_URL}` + `getUsers`, { headers: headers })
            .then(res => {
                const users = res.data.users;
                setUser(users);
                setIserror(false)
            })
    }, [])

    function TablePaginationActions(props) {
        const theme = useTheme();
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page"
                >
                    {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === "rtl" ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page"
                >
                    {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#1976d2",
            color: theme.palette.common.white,
            letterSpacing: 1,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            cursor: "pointer",
            letterSpacing: 1,
        },
    }));


    const saveData = async () => {
        const headers = {
            'Authorization': 'Bearer ' + Token
        }

        let check = []
        if (name === "") {
            alert("Please enter a valid Name")
            check.push("Please enter a valid Name")
        }
        if (emailId === "" || validateEmail(emailId) === false) {
            alert("Try Again, You didn't enter the valid emailId")
            check.push("Try Again, You didn't enter the valid emailId")
        }
        if (mobileNo === "" || validateNumber(mobileNo) === false) {
            alert("Oops!!! Please enter a valid MobileNo")
            check.push("Oops!!! Please enter a valid MobileNo")
        }
        if (password === "" || errorMessage !== '') {
            alert("Oops!!! Please enter a valid Password")
            check.push("Oops!!! Please enter a valid Password")
        }
        if (role === "") {
            alert("Try Again, Role field can't be blank")
            check.push("Try Again, Role field can't be blank")
        }
        if (country === "") {
            alert("Try Again, Enter Country before submitting")
            check.push("Try Again, Enter Country before submitting")
        }


        let newData = {
            userName: name,
            emailId: emailId,
            password: password,
            mobileNo: mobileNo,
            role: role,
            country: country
        }
        let data = {
            emailId: emailId
        }

        if (check.length == 0) {
            await axios.post(`${BASE_URL}` + `addUsers`, newData, { headers: headers })
                .then(response => {
                    let newUserdata = [...user];
                    newUserdata.push(newData);
                    setUser(newUserdata);
                    setOpen(false);
                    axios.post(`${BASE_URL}` + `sendVerificationCode`, data, { headers: headers })
                        .then(res => {
                            alert('Verification Code Send to your email Id')
                        })
                    axios.get(`${BASE_URL}` + `getUsers`, { headers: headers })
                        .then(res => {
                            const users = res.data.users;
                            setUser(users);

                        })
                })
                .catch(error => {

                })
        }
    }

    const editData = () => {
        let check = []
        if (name === "") {
            alert("Please enter a valid Name")
            check.push("Please enter a valid Name")
        }
        if (emailId === "" || validateEmail(emailId) === false) {
            alert("Try Again, You didn't enter the valid emailId")
            check.push("Try Again, You didn't enter the valid emailId")
        }
        if (mobileNo === "" || validateNumber(mobileNo) === false) {
            alert("Oops!!! Please enter a valid MobileNo")
            check.push("Oops!!! Please enter a valid MobileNo")
        }
        if (password === "" || errorMessage !== '') {
            alert("Oops!!! Please enter a valid Password")
            check.push("Oops!!! Please enter a valid Password")
        }
        if (role === "") {
            alert("Try Again, Role field can't be blank")
            check.push("Try Again, Role field can't be blank")
        }
        if (country === "") {
            alert("Try Again, Enter Country before submitting")
            check.push("Try Again, Enter Country before submitting")
        }

        let newData = {
            userName: name,
            emailId: emailId,
            password: password,
            mobileNo: mobileNo,
            role: role,
            country: country
        }
        const headers = {
            'Authorization': 'Bearer ' + Token
        }

        if (check.length === 0) {
            axios.put(`${BASE_URL}` + `editUsers`, newData, { headers: headers })
                .then(response => {
                    axios.get(`${BASE_URL}` + `getUsers`, { headers: headers })
                        .then(res => {
                            const users = res.data.users;
                            setUser(users);
                            setOpen(false)
                        })
                })
                .catch(error => {

                })
        }
    }

    const deleteClick = (row) => {
        setDelModalOpen(true);
        setRowData(row);

    }

    const statusClick = (row) => {
        const headers = {
            'Authorization': 'Bearer ' + Token
        }
        axios.post(`${BASE_URL}` + `updateStatus?userId=${row._id}&status=${row.status}`, { headers: headers })
            .then(response => {
                var stat = response.data.message;
                axios.get(`${BASE_URL}` + `getUsers`, { headers: headers })
                    .then(res => {
                        const users = res.data.users;
                        setUser(users);
                        alert(stat)
                    })
            })
            .catch(error => {

            })
    }

    const verifyCode = () => {
        let data = {
            emailId: rowData.emailId,
            verification_code: code
        }
        axios.post(`${BASE_URL}` + `checkVerificationCode`, data, { headers: headers })
            .then(response => {
                var stat = response.data.message;
                axios.get(`${BASE_URL}` + `getUsers`, { headers: headers })
                    .then(res => {
                        const users = res.data.users;
                        setUser(users);
                        alert(stat)
                        setModalOpen(false)
                    })
            })
            .catch(error => {

            })

    }

    const delData = () => {
        const headers = {
            'Authorization': 'Bearer ' + Token
        }
        axios.delete(`${BASE_URL}` + `deleteUsers?userId=${rowData._id}`, { headers: headers })
            .then(response => {
                axios.get(`${BASE_URL}` + `getUsers`, { headers: headers })
                    .then(res => {
                        const users = res.data.users;
                        setUser(users);
                        setDelModalOpen(false)
                    })
            })
            .catch(error => {

            })


    }

    const verficationCheck = (row) => {
        if (row.verification == 'Pending') {
            setModalOpen(true);
            setRowData(row);
            setCode('')
        }
    }


    const AddNewUsers = () => {
        setOpen(true);
        setName('');
        setEmailId('');
        setMobileNo('');
        setPassword('');
        setRole("");
        setCountry("");
        setNewUser(true);
    }

    // const decode = (data) => {
    //     const decipher = crypto.createDecipheriv("aes-256-cbc", "6fa979f20126cb08aa645a8f495f6d85", "7777777a72ddc2f1");

    //     let decryptedData = decipher.update(data, "binary", "utf-8");

    //     decryptedData += decipher.final("utf-8");

    //     return decryptedData
    // }

    const encode = (data) => {
        const cipher = crypto.createCipheriv("aes-256-cbc", "6fa979f20126cb08aa645a8f495f6d85", "7777777a72ddc2f1");

        let encryptedData = cipher.update(data, "utf-8", "binary");

        encryptedData += cipher.final("binary");

        return encryptedData
    }

    const EditClick = (row) => {
        console.log(row, 'rowwww')
        setOpen(true);
        setName(row.userName);
        setMobileNo(row.mobileNo);
        setEmailId(row.emailId);
        setRole(row.role);
        setCountry(row.country);
        setNewUser(false);
    };

    const onNameChange = (e) => {
        setName(e.target.value)
    }

    const onEmailChange = (e) => {
        setEmailId(e.target.value)
    }

    const onPhoneChange = (e) => {
        setMobileNo(e.target.value)
    }

    const onCodeChange = (e) => {
        setCode(e.target.value)
    }

    const validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessage('')
        } else {
            setErrorMessage('Password must have a minimum of 8 characters,at least one digit(0-9),one lowercase character(a-z) and one uppercase character(A_=-Z),one special character(!@#$%^&*)')
        }
    }

    const old_password_validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setOldErrorMessage('')
        } else {
            setOldErrorMessage('Password must have a minimum of 8 characters,at least one digit(0-9),one lowercase character(a-z) and one uppercase character(A_=-Z),one special character(!@#$%^&*)')
        }
    }

    const new_password_validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setNewErrorMessage('')
        } else {
            setNewErrorMessage('Password must have a minimum of 8 characters,at least one digit(0-9),one lowercase character(a-z) and one uppercase character(A_=-Z),one special character(!@#$%^&*)')
        }
    }

    const onPasswordChange = (e) => {
        validate(e.target.value)
        setPassword(e.target.value)
    }

    const onOldPasswordChange = (e) => {
        old_password_validate(e.target.value)
        setOldPassword(e.target.value)
    }

    const onNewPasswordChange = (e) => {
        new_password_validate(e.target.value)
        setNewPassword(e.target.value)
    }

    const onRoleChange = (e, data) => {
        setRole(data.value)
    }

    const onCountryChange = (e, data) => {
        setCountry(data.value)
    }


    const ChangePassword = () => {
        setEmailId('')
        setNewPassword('')
        setOldPassword('')
        setChangeModalOpen(true)
    }

    const updateNewPassword = () => {
        let data = {
            emailId: emailId,
            old_password: oldPassword,
            new_password: newPassword
        }
        axios.post(`${BASE_URL}` + `changePassword`, data, { headers: headers })
            .then(response => {
                var stat = response.data.message;
                alert(stat)
                setChangeModalOpen(false);
            })
            .catch(error => {

            })

    }


    return (
        <div style={{ margin: "5rem auto", width: "90vw" }}>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <Paper
                        elevation={1}
                        sx={{
                            width: "120%",
                            height: 45,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <h5 style={{ letterSpacing: 1, color: "blue", fontSize: 15 }}>
                            Users Details
                        </h5>
                    </Paper>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <Paper
                            elevation={1}
                            sx={{
                                width: "120%",
                                height: 45,
                                marginRight: 2,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <EditIcon
                                style={{
                                    minWidth: 25,
                                    minHeight: 25, color: 'blue', cursor: 'pointer', marginLeft: 10
                                }}
                                onClick={() => ChangePassword()}
                            />
                            <h5 style={{ letterSpacing: 1, color: "blue", fontSize: 15, marginBottom: 25, marginRight: 10 }}>
                                Change Password
                            </h5>
                        </Paper>

                    </div>
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        <Paper
                            elevation={1}
                            sx={{
                                width: "120%",
                                height: 45,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <AddCircleIcon
                                style={{
                                    minWidth: 25,
                                    minHeight: 25, color: 'blue', cursor: 'pointer', marginLeft: 10
                                }}
                                onClick={() => AddNewUsers()}
                            />
                            <h5 style={{ letterSpacing: 1, color: "blue", fontSize: 15, marginBottom: 25, marginRight: 10 }}>
                                Add Users
                            </h5>
                        </Paper>

                    </div>
                </div>




            </div>
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                style={{ display: 'flex', alignItems: 'center', marginTop: -100 }}
            >
                <Modal.Content>
                    <Form>
                        <Grid columns={2}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        label="User Name"
                                        type="text"
                                        placeholder="User Name"
                                        value={name}
                                        onChange={onNameChange}
                                    />

                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Input
                                        label="PhoneNumber"
                                        type="number"
                                        placeholder="phone number"
                                        value={mobileNo}
                                        onChange={onPhoneChange}
                                    />
                                </Grid.Column>

                                <Grid.Column style={{ marginTop: 20 }}>
                                    <Form.Input
                                        label="Email"
                                        type="email"
                                        placeholder="Email ID"
                                        value={emailId}
                                        style={{ color: "brown" }}
                                        onChange={onEmailChange}
                                    />

                                </Grid.Column>


                                {newUser == true && (
                                    <Grid.Column style={{ marginTop: 20 }}>
                                        <div style={{ position: 'absolute' }}>
                                            <Form.Input
                                                label={`Password`}
                                                type={showPass == true ? "text" : "password"}
                                                placeholder="password"
                                                value={password}
                                                style={{ width: '205%' }}
                                                onChange={onPasswordChange}

                                            />

                                        </div>
                                        <div style={{ position: 'relative', marginLeft: '80%', marginTop: 40 }}>
                                            <InputAdornment position="start">
                                                <IconButton
                                                    onClick={() => {
                                                        setShowPass(!showPass);
                                                    }}
                                                >

                                                    {showPass === false ? (
                                                        <VisibilityOffIcon />
                                                    ) : (
                                                        <VisibilityIcon />
                                                    )}

                                                </IconButton>
                                            </InputAdornment>
                                        </div>
                                        <div style={{ marginTop: 25 }}>
                                            <span style={{
                                                color: 'red'

                                            }}>{errorMessage}</span>
                                        </div>


                                    </Grid.Column>
                                )}

                                <Grid.Column style={{ marginTop: 20 }}>
                                    <h5>Role</h5>
                                    <Dropdown placeholder='Role' search selection options={roles}
                                        onChange={onRoleChange}
                                        value={role}
                                        style={{ width: '100%', marginTop: -10 }}
                                    />
                                </Grid.Column>

                                <Grid.Column style={{ marginTop: 20 }}>
                                    <h5>Country</h5>
                                    <Dropdown placeholder='Country' fluid multiple selection options={countryList}
                                        onChange={onCountryChange}
                                        value={country}
                                        style={{ width: '100%', marginTop: -10 }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>

                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        content="Save"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => newUser === true ? saveData() : editData()}
                        positive
                    />
                </Modal.Actions>
            </Modal>

            <Modal
                onClose={() => setModalOpen(false)}
                onOpen={() => setModalOpen(true)}
                open={modalopen}
                size={'small'}
                style={{ display: 'flex', alignItems: 'center', marginTop: -100 }}
            >
                <Modal.Content>
                    <Form>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Form.Input
                                        label="Code"
                                        type="number"
                                        placeholder="Verification Code"
                                        value={code}
                                        onChange={onCodeChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>

                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        content="Verify"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => verifyCode()}
                        positive
                    />
                </Modal.Actions>
            </Modal>

            <Modal
                onClose={() => setDelModalOpen(false)}
                onOpen={() => setDelModalOpen(true)}
                open={delModalopen}
                size={'tiny'}
                style={{ display: 'flex', alignItems: 'center', marginTop: -100 }}
            >
                <Modal.Content>
                    <Form>
                        <h3>Are you sure, you want to delete this data?</h3>
                    </Form>

                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={() => setDelModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        content="Delete"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => delData()}
                        positive
                    />
                </Modal.Actions>
            </Modal>

            <Modal
                onClose={() => setChangeModalOpen(false)}
                onOpen={() => setChangeModalOpen(true)}
                open={changeModalopen}
                size={'tiny'}
                style={{ display: 'flex', alignItems: 'center', marginTop: -100 }}
            >
                <Modal.Content>
                    <Form>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column style={{ marginTop: 20 }}>
                                    <Form.Input
                                        label="Email"
                                        type="email"
                                        placeholder="Email ID"
                                        value={emailId}
                                        style={{ color: "brown" }}
                                        onChange={onEmailChange}
                                    />
                                </Grid.Column>
                                <Grid.Column style={{ marginTop: 20 }}>
                                    <div style={{ position: 'absolute' }}>
                                        <Form.Input
                                            label={`Old Password`}
                                            type={showPass == true ? "text" : "password"}
                                            placeholder="password"
                                            value={oldPassword}
                                            style={{ width: '250%' }}
                                            onChange={onOldPasswordChange}

                                        />

                                    </div>
                                    <div style={{ position: 'relative', marginLeft: '80%', marginTop: 40 }}>
                                        <InputAdornment position="start">
                                            <IconButton
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}
                                            >

                                                {showPass === false ? (
                                                    <VisibilityOffIcon />
                                                ) : (
                                                    <VisibilityIcon />
                                                )}

                                            </IconButton>
                                        </InputAdornment>
                                    </div>
                                    <div style={{ marginTop: 25 }}>
                                        <span style={{
                                            color: 'red'

                                        }}>{oldErrorMessage}</span>
                                    </div>

                                </Grid.Column>
                                <Grid.Column style={{ marginTop: 20 }}>
                                    <div style={{ position: 'absolute' }}>
                                        <Form.Input
                                            label={`New Password`}
                                            type={showPass == true ? "text" : "password"}
                                            placeholder="password"
                                            value={newPassword}
                                            style={{ width: '250%' }}
                                            onChange={onNewPasswordChange}

                                        />

                                    </div>
                                    <div style={{ position: 'relative', marginLeft: '80%', marginTop: 40 }}>
                                        <InputAdornment position="start">
                                            <IconButton
                                                onClick={() => {
                                                    setShowPass(!showPass);
                                                }}
                                            >

                                                {showPass === false ? (
                                                    <VisibilityOffIcon />
                                                ) : (
                                                    <VisibilityIcon />
                                                )}

                                            </IconButton>
                                        </InputAdornment>
                                    </div>
                                    <div style={{ marginTop: 25 }}>
                                        <span style={{
                                            color: 'red'

                                        }}>{newErrorMessage}</span>
                                    </div>

                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>

                </Modal.Content>
                <Modal.Actions style={{ marginTop: 20 }}>
                    <Button color='black' onClick={() => setChangeModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        content="Update"
                        labelPosition='right'
                        icon='checkmark'
                        onClick={() => updateNewPassword()}
                        positive
                    />
                </Modal.Actions>
            </Modal>



            <TableContainer component={Paper} style={{ marginTop: 5, width: "100%" }}>
                <Table sx={{ minWidth: 660 }} stickyHeader aria-label="sticky table">
                    <TableHead>

                        <TableRow>
                            <StyledTableCell>User Name</StyledTableCell>
                            <StyledTableCell align="center">Email ID</StyledTableCell>
                            <StyledTableCell align="center">Mobile No</StyledTableCell>
                            <StyledTableCell align="center">Role</StyledTableCell>
                            <StyledTableCell align="center">Country</StyledTableCell>
                            <StyledTableCell align="center">Edit</StyledTableCell>
                            <StyledTableCell align="center">Delete</StyledTableCell>
                            <StyledTableCell align="center">User Status</StyledTableCell>
                            <StyledTableCell align="center">User Verfication</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>


                        {(rowsPerPage > 0
                            ? user.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            : user
                        ).map((row) => {
                            return (
                                <StyledTableRow
                                    key={row.userName}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <StyledTableCell
                                        component="th"
                                        scope="row"
                                        onClick={() => {
                                            rowClick(row);
                                        }}
                                    >
                                        {row.userName}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        onClick={() => {
                                            rowClick(row);
                                        }}
                                    >
                                        {row.emailId}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        onClick={() => {
                                            rowClick(row);
                                        }}
                                    >
                                        {row.mobileNo}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        onClick={() => {
                                            rowClick(row);
                                        }}
                                    >
                                        {row.role}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        onClick={() => {
                                            rowClick(row);
                                        }}
                                    >
                                        {row.country}
                                    </StyledTableCell>

                                    <StyledTableCell
                                        align="center"
                                        onClick={() => {
                                            EditClick(row);
                                        }}
                                    >
                                        <EditIcon color="success" />
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        onClick={() => {
                                            deleteClick(row);
                                        }}
                                    >
                                        <DeleteIcon color="error" />
                                    </StyledTableCell>

                                    <StyledTableCell
                                        align="center"
                                        onClick={() => {
                                            statusClick(row);
                                        }}
                                    >
                                        {console.log(row.status)}
                                        <PowerSettingsNewIcon
                                            color={row.status == 'Active' ? 'success' : 'error'}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        onClick={() => {
                                            verficationCheck(row);
                                        }}
                                    >
                                        {row.verification == 'Success' ? <DoneOutlineIcon
                                            color={'success'}
                                        /> : <PausePresentationIcon color={'warning'} />

                                        }

                                    </StyledTableCell>


                                </StyledTableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <StyledTableRow style={{ height: 53 * emptyRows }}>
                                <StyledTableCell colSpan={6} />
                            </StyledTableRow>
                        )}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                                colSpan={8}
                                count={user.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "rows per page",
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>


                </Table>
            </TableContainer>

        </div >
    );
}

export default Users;