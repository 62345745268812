import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const SelectSearch = (props) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(props.selected);
  }, [props.selected]);
  return (
    <Autocomplete
      id="combo-box-demo"
      placeholder={props.placeholder}
      options={props.opt}
      style={{ width: "20vw" }}
      value={value}
      onChange={(event, value) => {
        if (value !== null) {
          props.setLabel(event, value.label);
        }
      }}
      renderInput={(params) => (
        <TextField {...params} label={props.placeholder} />
      )}
    />
  );
};

export default SelectSearch;
