import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import MultiCrops from "react-multi-crops";
import { Button, Box, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { OcrFileTranslateText, getCellWiseData } from "../actions/OcrAction";
import {
  getCellWiseProcessedData,
  setCurrentPageInc,
  setAlteredData,
  clearPageIndex,
  setHandleDisableNext,
  setHandleDisablePre,
} from "../reducers/OcrReducer";

import Fab from "@mui/material/Fab";

import {
  Paper,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Tooltip,
  Chip,
} from "@mui/material";
import AlertBar from "./CommonUtils/AlertBar";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import ProcessedData from "./proccessedData";

import Divider from "@mui/material/Divider";

// import { Carousel } from 'react-carousel-minimal';

import "./table.css";
const BaseUrl = process.env.REACT_APP_BASE_URL;
const ImageBaseUrl = process.env.REACT_APP_STORAGE_BASE_URL;

const Crop = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [notPreview, setNotpreview] = useState(false);
  const [currentUrl, setCurrentUrl] = useState("");
  const [coordinates, setCoordinates] = useState([]);

  const [itemData, setItemData] = useState([]);
  const [CurrentAwsPicUrl, setCurrentAwsPicUrl] = useState("");
  const [Row, setRow] = useState("");
  const [Column, setColumn] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [shownextTooltip, setnextShowTooltip] = useState(false);
  const [nexttooltipMessage, setnextTooltipMessage] = useState("");
  const [showprevTooltip, setprevShowTooltip] = useState(false);
  const [prevtooltipMessage, setprevTooltipMessage] = useState("");
  const [selectedRowAndColumn, setSelectedRowAndColumn] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  const [currentFilename, setCurrentFileName] = useState("");

  const getPageIndex = useSelector((state) => state.ocr.currentPage);
  const processedDataById = useSelector((state) => state.ocr.processedDataById);
  const pre = useSelector((state) => state.ocr.disablePre);
  const next = useSelector((state) => state.ocr.disableNext);

  const allOcrValue = useSelector((state) => state.ocr);
  const [expanded, setExpanded] = React.useState(false);
  const [formRows, setFormRows] = useState([]);
  const [formColumns, setFormColumns] = useState([]);
  const [clientID, setClientID] = useState("");
  const [templateID, setTemplateID] = useState("");
  const [disablePrev, setDisablePre] = useState(true);
  const [disableNext, setDisableNext] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [fileLength, setFileLength] = useState(0);

  useEffect(() => {
    setDisablePre(pre);
    setDisableNext(next);
    return () => {
      dispatch(clearPageIndex());
      dispatch(setHandleDisableNext({ type: "next", payload: false }));
      dispatch(setHandleDisablePre({ type: "pre", payload: true }));
    };
  }, []);
  useEffect(() => {
    setDisablePre(pre);
    setDisableNext(next);
  }, [pre, next]);
  useEffect(() => {
    if (Object.keys(processedDataById).length !== 0) {
      setItemData(processedDataById["imageUploadedUrls"]);
      setCurrentFileName(processedDataById["fileName"]);
      setClientID(processedDataById["clientId"]);
      setTemplateID(processedDataById["templateId"]);
      setFileLength(processedDataById["processedData"].length);
      setCurrentIndex(getPageIndex);
      let imageUrl = processedDataById.processedData[currentIndex].imageUrl;

      setCurrentImage(imageUrl);
    }
  }, [processedDataById]);

  useEffect(() => {
    let rows = [];
    let columns = [];

    if (Object.keys(processedDataById).length !== 0) {
      let columnCount =
        processedDataById.processedData[currentIndex].columnCount !== undefined
          ? processedDataById.processedData[currentIndex].columnCount
          : 0;
      let rowCount =
        processedDataById.processedData[currentIndex].rowCount !== undefined
          ? processedDataById.processedData[currentIndex].rowCount
          : 0;
      let imageUrl = processedDataById.processedData[currentIndex].imageUrl;
      for (let i = 1; i <= columnCount; i++) {
        columns.push(`C${i}`);
      }
      for (let i = 1; i <= rowCount; i++) {
        rows.push(`R${i}`);
      }
      setCurrentImage(imageUrl);
    }

    setFormColumns([...columns]);
    setFormRows([...rows]);
  }, [currentIndex]);

  const handleRowChange = async (event) => {
    setRow(event.target.value);
    setColumn("");
    setOpenSnack(false);
    setAlertColor("");
    setSnackMessage("");
  };

  const changeCoordinate = (coordinate, index, coordinates) => {
    setCoordinates(coordinates);
  };
  const deleteCoordinate = (coordinate, index, coordinates) => {
    setCoordinates(coordinates);
  };

  const getCoordinates = async () => {
    let cropped = {
      photoUrl: CurrentAwsPicUrl,
      coordinates: coordinates,
    };

    let translatedResult = await OcrFileTranslateText({
      url: BaseUrl + "getTextfromImage",
      cropped,
    });
    setNotpreview(false);
    setCoordinates([]);
  };

  const PreviousImage = () => {
    if (currentIndex === 1) {
      dispatch(setHandleDisablePre({ type: "pre", payload: true }));
    }
    if (currentIndex > 0) {
      dispatch(
        setCurrentPageInc({
          type: "currentPage",
          payload: currentIndex - 1,
        })
      );
      // dispatch(setAlteredData({ type: "altered", payload: [] }));
      setCurrentIndex(currentIndex - 1);
      dispatch(setHandleDisableNext({ type: "next", payload: false }));
    } else {
      dispatch(
        setCurrentPageInc({
          type: "currentPage",
          payload: currentIndex,
        })
      );
      dispatch(setHandleDisablePre({ type: "pre", payload: true }));

      // dispatch(setAlteredData({ type: "altered", payload: [] }));

      setCurrentIndex(currentIndex);
    }
    setSelectedRowAndColumn([]);
  };
  const nextImage = () => {
    let totalImage = itemData.length;
    if (currentIndex === totalImage - 2) {
      dispatch(setHandleDisableNext({ type: "next", payload: true }));
    }

    if (currentIndex < totalImage - 1) {
      dispatch(
        setCurrentPageInc({
          type: "currentPage",
          payload: currentIndex + 1,
        })
      );
      // dispatch(setAlteredData({ type: "altered", payload: [] }));
      dispatch(setHandleDisablePre({ type: "pre", payload: false }));

      setCurrentIndex(currentIndex + 1);
    } else {
      dispatch(
        setCurrentPageInc({
          type: "currentPage",
          payload: currentIndex,
        })
      );
      // dispatch(setAlteredData({ type: "altered", payload: [] }));

      setCurrentIndex(currentIndex);
    }
    setnextShowTooltip(false);
    setSelectedRowAndColumn([]);
  };
  const PreTooltip = () => {
    if (currentIndex > 0) {
      setprevShowTooltip(false);
      setnextShowTooltip(false);
    } else {
      setprevShowTooltip(true);
      setprevTooltipMessage("This is the last image");
    }
  };
  const nextTooltip = () => {
    let totalImage = itemData.length;
    setnextTooltipMessage(false);
    if (currentIndex < totalImage - 1) {
      setnextShowTooltip(false);
      setprevShowTooltip(false);
    } else {
      setnextShowTooltip(true);
      setnextTooltipMessage("This is the last image");
    }
  };
  const handleClose = () => {
    setOpenSnack(false);
  };
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  // const handleDelete = (rowAndColumn) => {
  //   const filtered = selectedRowAndColumn.filter(
  //     (item) => item.createdString !== rowAndColumn.createdString
  //   );
  //   setSelectedRowAndColumn([...filtered]);
  // };

  const extractData = async () => {
    let a = [...selectedRowAndColumn];
    let format = [];
    a.forEach((data) => {
      format.push({
        row: data.row.substr(1),
        column: data.col.substr(1),
      });
    });
    let extractObj = {
      clientId: clientID,
      templateId: templateID,
      fileName: currentFilename,
      imageUrl: itemData[currentIndex],
      pageNo: currentIndex + 1,
      translateData: format,
    };
    let result = await getCellWiseData({
      url: `${BaseUrl}getCellWiseData`,
      input: extractObj,
    });

    if (result.error !== undefined && result.error === true) {
      setAlertColor("error");
      setSnackMessage("Failed To Fetch data");
      setOpenSnack(true);
    }
    if (result.status === 200) {
      dispatch(getCellWiseProcessedData(result.data));
      setOpenSnack(true);

      setAlertColor("success");

      setSnackMessage("File Extracted");
    } else {
    }
  };

  var role = localStorage.getItem('role')

  return (
    <Box
      sx={{
        flexGrow: 1,
        overflowY: "hidden",
        height: "620px",
      }}
    >
      <AlertBar
        openSnack={openSnack}
        handleClose={handleClose}
        alertColor={alertColor}
        snackMessage={snackMessage}
      />
      <Grid sx={{ p: 1 }} container direction={"column"} spacing={1}>
        <Divider component="li" sx={{ listStyle: "none", mt: 1 }} />
        <Grid item xs={12}>
          <Grid container direction={"row"} sx={{ height: "75vh" }} spacing={3}>
            <Grid item xs={6}>
              <Paper
                elevation={2}
                style={{
                  border: "1px solid #0074e8",
                  height: "80vh",
                  overflowY: "scroll",
                }}
              >
                <Grid container direction={"column"}>
                  <Grid item>
                    {itemData.length === 0 ? (
                      <div
                        style={{
                          height: "400px",
                          width: "100%",
                          marginTop: 5,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <h4>No Images Found</h4>
                      </div>
                    ) : (
                      <img
                        src={currentImage}
                        style={{
                          height: "520px",
                          width: "100%",
                          backgroundSize: "contains",
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={6} sx={{ ml: 4, mb: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Grid item>
                        <Tooltip
                          open={showprevTooltip}
                          title={prevtooltipMessage}
                          leaveDelay={0}
                          leaveTouchDelay={1}
                        >
                          <Fab
                            color="primary"
                            aria-label="add"
                            onClick={PreviousImage}
                            onMouseOver={PreTooltip}
                            disabled={disablePrev}
                            onMouseLeave={() => {
                              setprevShowTooltip(false);
                            }}
                            size="small"
                          >
                            <SkipPreviousIcon />
                          </Fab>
                        </Tooltip>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={`page  ${currentIndex + 1} of  ${fileLength}`}
                          variant="outlined"
                          color="primary"
                          size="medium"
                        ></Chip>
                      </Grid>
                      <Grid item>
                        <Tooltip
                          open={shownextTooltip}
                          title={nexttooltipMessage}
                        >
                          <Fab
                            color="primary"
                            aria-label="add"
                            onClick={nextImage}
                            onMouseOver={nextTooltip}
                            onMouseLeave={() => {
                              setnextTooltipMessage(!nextTooltip);
                            }}
                            disabled={disableNext}
                            size="small"
                          >
                            <SkipNextIcon />
                          </Fab>
                        </Tooltip>
                      </Grid>
                      {role !== 'viewer' && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              window.open("/CropView");
                              localStorage.setItem(
                                "ocr",
                                JSON.stringify(allOcrValue)
                              );
                            }}
                          >
                            Extract data from image
                          </Button>
                        </Grid>
                      )}

                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <ProcessedData />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Crop;
