import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import { useTheme } from "@mui/material/styles";

import { styled } from "@mui/material/styles";
import { Paper, Chip, Box } from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useNavigate } from "react-router-dom";
import { setCurrentFile } from "../../reducers/OcrReducer";
import { useDispatch } from "react-redux";
import AlertBar from "./AlertBar";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
// import { setCurrentFile } from "../../reducers/OcrReducer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1976d2",
    color: theme.palette.common.white,
    letterSpacing: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    cursor: "pointer",
    letterSpacing: 1,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const FormTable = (props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [alertColor, setAlertColor] = React.useState("");
  const [snackMessage, setSnackMessage] = React.useState("");
  const [allRows, setAllRows] = React.useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storageBaseUrl = "https://auditpro-prod2.s3.amazonaws.com/"

  var role = localStorage.getItem('role')

  useEffect(() => {
    if (props.rows) {
      setAllRows([...props.rows]);
    }
  }, [props.rows]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allRows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const rowClick = (row) => {
    props.fetchDataById(row);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const DownloadClick = (row) => {
    console.log(row,'row')
    window.open(storageBaseUrl+row.fileUploadedUrl)
    // props.downloadReport(row);
  };
  const EditClick = (row) => {
    props.fetchDataById(row);
  };
  const deleteClick = (row) => {
    props.DeleteFileById(row);
  };

  return (
    <>
      <AlertBar
        openSnack={openSnack}
        handleClose={handleClose}
        alertColor={alertColor}
        snackMessage={snackMessage}
      />
      <TableContainer component={Paper} style={{ marginTop: 5, width: "100%" }}>
        <Table sx={{ minWidth: 660 }} stickyHeader aria-label="sticky table">
          <TableHead>

            {(role === 'admin' || role === 'editor') && (
              <TableRow>
                <StyledTableCell>Country</StyledTableCell>
                <StyledTableCell align="center">Template</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>

                <StyledTableCell align="center">File name</StyledTableCell>
                <StyledTableCell align="center">Processing Date</StyledTableCell>
                <StyledTableCell align="center">Processing Duration(In Minutes)</StyledTableCell>
                <StyledTableCell align="center">Report</StyledTableCell>
                {/* <StyledTableCell align="center">Edit</StyledTableCell> */}
                {/* <StyledTableCell align="center">Delete</StyledTableCell> */}
              </TableRow>

            )}
            {role === 'viewer' && (
              <TableRow>
                <StyledTableCell>Country</StyledTableCell>
                <StyledTableCell align="center">Template</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>

                <StyledTableCell align="center">File name</StyledTableCell>
                <StyledTableCell align="center">Processing Date</StyledTableCell>
                <StyledTableCell align="center">Processing Duration(In Minutes)</StyledTableCell>

                <StyledTableCell align="center">Report</StyledTableCell>
                {/* <StyledTableCell align="center">Edit</StyledTableCell> */}
              </TableRow>
            )}


          </TableHead>
          {(role === 'admin' || role === 'editor') && (
            <TableBody>


              {(rowsPerPage > 0
                ? allRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : allRows
              ).map((row) => {
                return (
                  <StyledTableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      // onClick={() => {
                      //   rowClick(row);
                      // }}
                    >
                      {row.clientName}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      // onClick={() => {
                      //   rowClick(row);
                      // }}
                    >
                      {row.templateName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Chip
                        label={row.status}
                        color={
                          row.status === "Processing" ? "warning" : row.status === "Failed" ? "error" : row.status == "Processed" ? "success" : null
                        }
                        icon={
                          row.status === "Processing" ? (<RotateRightIcon />) : row.status === "Failed" ? (<RotateRightIcon />) : row.status === "Processed" ?
                            (
                              <DoneIcon />
                            ) : null
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      // onClick={() => {
                      //   rowClick(row);
                      // }}
                    >
                      {row.fileName}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      // onClick={() => {
                      //   rowClick(row);
                      // }}
                    >
                      {String(row.startTime).split("T")[0]}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      // onClick={() => {
                      //   rowClick(row);
                      // }}
                    >
                      {row.minutes}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        DownloadClick(row);
                      }}
                    >
                      {row.name !== "" ? <DownloadForOfflineIcon /> : ""}
                    </StyledTableCell>

                    {/* {(row.templateName == 'Egypt - Excel'|| row.templateName == 'Excel 1 - VI') ? (
                        <StyledTableCell
                        align="center"
                      >
                        {row.status === "Processed" ? (<DoNotDisturbIcon color="success" />) : row.status === "Queued" ? (<DoNotDisturbIcon color="warning" />) : row.status === "Processing" ? (<DoNotDisturbIcon color="warning" />) : null}
                      </StyledTableCell>
                    ):
                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        EditClick(row);
                      }}
                    >
                      {row.status === "Processed" ? (<EditIcon color="success" />) : row.status === "Queued" ? (<DoNotDisturbIcon color="warning" />) : row.status === "Processing" ? (<DoNotDisturbIcon color="warning" />) : null}
                    </StyledTableCell>
                    } */}
                     

                    
                    {/* <StyledTableCell
                      align="center"
                      onClick={() => {
                        deleteClick(row);
                      }}
                    >
                      <DeleteIcon color="error" />
                    </StyledTableCell> */}


                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
          )}

          {role === 'viewer' && (
            <TableBody>


              {(rowsPerPage > 0
                ? allRows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                : allRows
              ).map((row) => {
                return (
                  <StyledTableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      onClick={() => {
                        rowClick(row);
                      }}
                    >
                      {row.clientName}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        rowClick(row);
                      }}
                    >
                      {row.templateName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Chip
                        label={row.status}
                        color={
                          row.status === "Processing" ? "warning" : row.status === "Failed" ? "error" : row.status == "Processed" ? "success" : null
                        }
                        icon={
                          row.status === "Processing" ? (<RotateRightIcon />) : row.status === "Failed" ? (<RotateRightIcon />) : row.status === "Processed" ?
                            (
                              <DoneIcon />
                            ) : null
                        }
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        rowClick(row);
                      }}
                    >
                      {row.fileName}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        rowClick(row);
                      }}
                    >
                      {String(row.startTime).split("T")[0]}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        rowClick(row);
                      }}
                    >
                      {row.minutes}
                    </StyledTableCell>

                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        DownloadClick(row);
                      }}
                    >
                      {row.name !== "" ? <DownloadForOfflineIcon /> : ""}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      onClick={() => {
                        EditClick(row);
                      }}
                    >
                      {row.status === "Processed" ? (<EditIcon color="success" />) : row.status === "Failed" ? (<DoNotDisturbIcon color="warning" />) : row.status === "Processing" ? (<DoNotDisturbIcon color="warning" />) : null}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
              {emptyRows > 0 && (
                <StyledTableRow style={{ height: 53 * emptyRows }}>
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
          )}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={8}
                count={allRows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

export default FormTable;
