import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button } from "@mui/material";
import validator from 'validator';
import axios from 'axios';

import { useSearchParams } from "react-router-dom";
import { isExpired, decodeToken } from "react-jwt";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function ForgotPassword() {

    const [searchParams, setSearchParams] = useSearchParams();
    let token = searchParams.get("email")
    const decodedToken = decodeToken(token);
    var isMyTokenExpired = isExpired(token);
    console.log(decodedToken,'decoded')
    console.log(isMyTokenExpired,'isExpired')
    if(isMyTokenExpired == true){
        alert('Token Expired.Please ReInitiate the same steps to reset the new password')
    }
    
    const [errorMessage, setErrorMessage] = React.useState('');

    const [values, setValues] = React.useState({
        amount: '',
        newPassword: '',
        confirmPassword: '',
        weight: '',
        weightRange: '',
        showPassword: false,
        showConfirmPassword: false
    });

    const handleNewChange = (prop) => (event) => {
        validate(event.target.value)
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleConfirmChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowNewPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleClickShowConfirmPassword = () => {
        setValues({
            ...values,
            showConfirmPassword: !values.showConfirmPassword,
        });
    };

    const handleMouseDownNewPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const validate = (value) => {

        if (validator.isStrongPassword(value, {
            minLength: 8,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            setErrorMessage('')
        } else {
            setErrorMessage('Password must have a minimum of 8 characters,at least one digit(0-9),one lowercase character(a-z) and one uppercase character(A_=-Z),one special character(!@#$%^&*)')
        }
    }


    const saveData = () => {
        if (values.newPassword !== values.confirmPassword) {
            alert('Password not Match')
        }
        else {
            let data = {
                emailId: decodedToken.emailId,
                old_password: values.newPassword,
                new_password: values.confirmPassword
            }
            axios.post(`${BASE_URL}` + `resetPassword`, data)
                .then(response => {
                    var stat = response.data.message;
                    alert(stat)
                })
                .catch(error => {

                })
        }
    }

    return (
        <Box sx={{
            display: 'flex', flexWrap: 'wrap', position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            height: 300,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>

                <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.newPassword}
                        onChange={handleNewChange('newPassword')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowNewPassword}
                                    onMouseDown={handleMouseDownNewPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="New Password"
                    />
                    {/* <div style={{ marginTop: 25 }}>
                        <span style={{
                            color: 'red'

                        }}>{errorMessage}</span>
                    </div> */}
                </FormControl>
                <FormControl sx={{ m: 1, width: '50ch' }} variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showConfirmPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        onChange={handleConfirmChange('confirmPassword')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownConfirmPassword}
                                    edge="end"
                                >
                                    {values.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Confirm Password"
                    />
                </FormControl>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={saveData}
                        disabled={isMyTokenExpired == true ? true : false}
                        style={{ display: 'flex', marginTop: 40 }}
                    >
                        SAVE
                    </Button>
                </div>

            </div>


        </Box>
    );
}
